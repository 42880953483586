import React, { useEffect, useState } from 'react';
import '../Videos/Videos.css';

function Videos() {
  const url = "https://themithraa.com/RestApi/WebApi/tutorial.php";
  const [tutorials, setTutorials] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedTutorialIds, setExpandedTutorialIds] = useState([]);

  useEffect(() => {
    const fetchTutorials = async () => {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
          },
          body: JSON.stringify({
            "api_key": "Mithra@2024"
          })
        });

        if (!response.ok) {
          throw new Error('Failed to fetch tutorials.');
        }

        const data = await response.json();

        if (data && Array.isArray(data.Data)) {
          const formattedTutorials = data.Data.map((item) => {
            const videoMatch = item.tut_url.match(/src="([^"]+)"/);
            return {
              ...item,
              tut_url: videoMatch ? videoMatch[1] : item.tut_url
            };
          });
          setTutorials(formattedTutorials);
        } else {
          throw new Error('Invalid response format.');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTutorials();
  }, []);

  const toggleDetails = (id) => {
    setExpandedTutorialIds((prevIds) =>
      prevIds.includes(id) ? prevIds.filter(tutId => tutId !== id) : [...prevIds, id]
    );
  };

  const renderDescription = (description, id) => {
    const isExpanded = expandedTutorialIds.includes(id);
    const truncatedDescription = description.slice(0, 180);

    return (
      <p>
        {isExpanded || description.length <= 180 ? description : `${truncatedDescription}...`}
        {description.length > 180 && (
          <button className="read-more-btn m-1" onClick={() => toggleDetails(id)}>
            {isExpanded ? 'Show Less' : 'Read More'}
          </button>
        )}
      </p>
    );
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (tutorials.length === 0) {
    return <div className="no-tutorials">No tutorials available at the moment.</div>;
  }

  return (
    <div className="tutorial-details p-5">
      <div className="brushstroke-container">
        <span className="brushstroke-text">Testimonial Videos</span>
      </div>

      <div className="card-container"> {/* Changed to card-container */}
        {tutorials.map((tutorial) => (
          <div className="card" key={tutorial.tut_id}> {/* Changed to card */}
            <h5>{tutorial.tut_name}</h5>
            {tutorial.tut_url && (
              <iframe
                width="100%"
                height="200"
                src={tutorial.tut_url}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            )}
            {renderDescription(tutorial.tut_details, tutorial.tut_id)}
            <p className="created-date">{tutorial.tut_created}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Videos;
