import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import '../Achivers/Achivers.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Achivers = () => {
  const [achieversData, setAchieversData] = useState([]);

  useEffect(() => {
    fetch('https://themithraa.com//RestApi/WebApi/achievers.php')
      .then(response => response.json())
      .then(data => {
        if (data.status) {
          const uniqueAchievers = data.Achievers.filter(
            (achiever, index, self) =>
              index === self.findIndex(a => a.acheiver_id === achiever.acheiver_id)
          );
          console.log("Filtered Achievers Data:", uniqueAchievers);
          setAchieversData(uniqueAchievers);
        } else {
          console.error('Failed to fetch achievers');
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  
  const sliderSettings = {
    dots: false,
    infinite: achieversData.length > 1,
    speed: 500,
    slidesToShow: Math.min(3, achieversData.length), // Show only as many slides as available data
    slidesToScroll: 1,
    autoplay: achieversData.length > 1,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(2, achieversData.length),
          slidesToScroll: 1,
          infinite: achieversData.length > 1,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='achivers-container container'>
      <div className="about-us-title brushstroke-container fade-in">
        <span className="brushstroke-text">Achievers</span>
      </div>
      {achieversData.length > 0 ? (
        <Slider {...sliderSettings}>
          {achieversData.map(achiever => (
            <div key={achiever.acheiver_id}>
              <div className="achiever-card">
                <div className="avatar-placeholder">
                  {achiever.image ? (
                    <img src={achiever.image} alt={achiever.name} className="avatar-image card-img-top" />
                  ) : (
                    <div className="avatar-fallback">No Image</div>
                  )}
                </div>
                <div className="card-body">
                  <h3 className="card-title">{achiever.name} <span className="year">'{achiever.age}</span></h3>
                  <p className="house card-text">{achiever.location}</p>
                  <p className="time card-text">{achiever.timing} <span className="pattern">({achiever.game})</span></p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <p>No achievers to display</p>
      )}
    </div>
  );
};

export default Achivers;
